<template>
  <div class="container p-8 space-y-8 mt-32 mx-auto w-full">

    <div class="grid cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mx-auto">

      <div class="container">

        <img src="/images/about/WhatsApp Image 2024-12-23 at 08.41.37.jpeg"
             class="w-full object-cover rounded-md"
             alt="Main Image">

      </div>


      <div class="container mx-auto justify-center items-center mt-32 w-full p-8">

        <h1 class="title text-4xl text-center w-full text-black font-bold">Cultivated By
          Passion</h1>

        <p class="text text-lg font-bold text-black text-center p-8"
           style="font-family: 'Lora', serif;">Delicias
          del pan
          was
          founded
          in 2023 by
          Leidy & Orlando.
          Cultivated by passion, the products of Delicias Del Pan are crafted using
          only natural ingredients, staying true to their artisanal nature.</p>

      </div>

    </div>

    <!-- create a responsive grid container for the images -->


  </div>

  <section class="" id="social_media mt-0">

    <div class="container mx-auto p-6">
      <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
        <!-- Image 1 (large), spans 2 columns and 2 rows on large screens -->
        <div class="lg:col-span-2 lg:row-span-2 overflow-hidden relative group">
          <img
              src="/images/about/WhatsApp%20Image%202024-12-23%20at%2008.41.37.jpeg"
              alt="About Us 1"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 2 -->
        <div class="overflow-hidden relative group">
          <img
              src="/images/about/WhatsApp%20Image%202024-12-23%20at%2008.41.38.jpeg"
              alt="About Us 2"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 3 -->
        <div class="overflow-hidden relative group">
          <img
              src="/images/about/WhatsApp%20Image%202024-12-23%20at%2008.52.03.jpeg"
              alt="About Us 3"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 4 -->
        <div class="overflow-hidden relative group">
          <img
              src="/images/about/WhatsApp%20Image%202024-12-23%20at%2008.52.04.jpeg"
              alt="About Us 4"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 5 -->
        <div class="overflow-hidden relative group">
          <img
              src="/images/about/WhatsApp%20Image%202024-12-23%20at%2008.52.05%20(1).jpeg"
              alt="About Us 5"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 6 -->
        <div class="overflow-hidden relative group row-span-1">
          <img
              src="/images/about/WhatsApp%20Image%202024-12-23%20at%2008.52.05.jpeg"
              alt="About Us 6"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>
        <!-- Image 7 -->
        <div class="overflow-hidden relative group row-span-1">
          <img
              src="/images/about/WhatsApp%20Image%202024-12-23%20at%2008.52.22.jpeg"
              alt="About Us 7"
              class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105 scroll-animate"
          />
        </div>

      </div>
    </div>

  </section>

</template>

<script>
export default {
  name: "AboutPage",
};
</script>